<template>
  <div
    v-if="productNo"
    class="min_h_100_vh dis_flex flex_dir_col"
    :style="`background: url('https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/IzGtDsDiUzVatCyqaJyE.png') no-repeat center/cover`"
  >
    <!-- 大标题 -->
    <div
      class="dis_flex ju_con_cen font_32 col_fff text_sha_0_2_4_CBE7FE m_t_32"
    >
      钱拿走 车照开
    </div>

    <!-- 宣传语 -->
    <div class="dis_flex ju_con_cen font_14 col_fff m_t_24">
      快速审批/多城市覆盖/专业评估/在线签约
    </div>

    <!-- 大图片 -->
    <div class="dis_flex ju_con_cen">
      <img
        class="w_160 h_160 dis_flex"
        src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/riCWSfZSIjosNfhhmjPI.png"
      />
    </div>

    <!-- 输入 -->
    <div class="bg_col_fff bor_rad_8 p_16 m_0_16 m_t_16">
      <!-- 申请信息 -->
      <div class="dis_flex ju_con_cen ali_it_cen">
        <img
          class="h_16 dis_flex"
          src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/PrKkkLMnXlNZyNQXDSaY.png"
        />
        <div class="font_18 font_bold col_2396F5 white_space_nowrap m_0_16">
          申请信息
        </div>
        <img
          class="h_16 dis_flex tras_rot_180_deg"
          src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/PrKkkLMnXlNZyNQXDSaY.png"
        />
      </div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_0 m_t_16">
        <div class="font_14">申请人姓名</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.name"
          type="text"
          placeholder="请输入"
          maxlength="10"
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_0">
        <div class="font_14">申请人手机号</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.mobile"
          type="text"
          placeholder="请输入"
          maxlength="11"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              form = { ...form, mobile: v };
            }
          "
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_0">
        <div class="font_14">验证码</div>

        <input
          class="w_0 flex_1 font_14 text_ali_r bor_none m_l_16"
          v-model="form.smsCode"
          type="text"
          placeholder="请输入"
          maxlength="4"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              form = { ...form, smsCode: v };
            }
          "
        />

        <div class="font_14 send_code" @click="sendCodeAction">
          <div class="bg_col_2396F5" v-if="+num === 60">{{ texts }}</div>
          <div class="bg_afafaf" v-else>{{ texts }}</div>
        </div>
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_F5F7F9"></div>

      <!-- 提交 -->
      <div
        class="
          dis_flex
          ju_con_cen
          font_16
          l_h_100_per
          col_fff
          bg_col_2396F5
          active_opa_75_per
          bor_rad_24
          p_16_0
          m_0_16 m_t_24
        "
        @click="submit()"
      >
        立即申请
      </div>

      <!--  -->
    </div>

    <!-- 轻松到账 -->
    <div class="dis_flex ju_con_cen">
      <div
        class="
          font_18
          l_h_100_per
          col_fff
          bg_col_lin_90_51a4fd_389df9
          bor_rad_24
          p_8_16
          m_t_32
        "
      >
        简单4步 轻松到账
      </div>
    </div>

    <!-- 步骤 -->
    <div class="dis_flex ju_con_cen m_0_16 m_t_24">
      <!-- 项 -->
      <div
        v-for="(item, index) in [
          {
            label: '在线审批',
            iconUrl:
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/hgwmUBsftnmTbZtumZCS.png',
          },
          {
            label: '在线签约',
            iconUrl:
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/rBDBJTAmCrxHrSFsGqEm.png',
          },
          {
            label: '抵押登记',
            iconUrl:
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/XapfMwmBXvuDXrRmTWbm.png',
          },
          {
            label: '钱款到账',
            iconUrl:
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/ldNFTINZIoeBKIcWvhwE.png',
          },
        ]"
        :key="index"
        class="dis_flex ali_it_cen"
      >
        <!-- 箭头 -->
        <img
          v-show="index > 0"
          class="h_12 dis_flex m_0_8"
          src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/OgzoOZGuGcpSsgoEFxsz.png"
        />

        <div class="flex_1 dis_flex flex_dir_col ali_it_cen">
          <div
            class="
              w_56
              h_56
              dis_flex
              ju_con_cen
              ali_it_cen
              bg_col_fff
              bor_rad_50_per
            "
          >
            <img class="w_32 h_32 dis_flex" :src="item.iconUrl" />
          </div>

          <div class="font_14 col_fff white_space_nowrap m_t_8">
            {{ item.label }}
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 留白 -->
    <div class="h_56"></div>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
const moment = window.moment;
import { isMobile, getLocation } from '@/utils/tools';

import {
  loanIncomingPartsApplyFirstPost,
  loanIncomingPartsSms
} from '@/api/index';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      productNo: null,
      brokerId: null,
      channelCode: null,
      officialAccountKey: null,
      invitationCode: null,

      form: {},

      areaProvincialList: [], // 省市
      nationList: [], // 民族
      vehicleCategoryList: [], // 车辆类别
      applyTermList: [], // 期数

      timer: null,
      texts: '发送验证码',
      status: false,
      num: 60,
    };
  },
  computed: {},
  mounted() {
    const query = this.$route.query;
    if (!query.productNo) {
      alert('没有检测到必须参数');
      return;
    }
    this.productNo = query.productNo;
    this.brokerId = query.brokerId;
    this.channelCode = query.channelCode;
    this.officialAccountKey = query.officialAccountKey;
    this.invitationCode = query.invitationCode;

    // 1.0.4废弃
    /* if (sessionStorage.enterDataForm) {
      this.form = JSON.parse(sessionStorage.enterDataForm);
    } */

    getLocation().then((o) => {
      this.form = {
        ...this.form,
        ...o,
      };
    });

    //
  },
  methods: {
    moment,

    // 提交
    async submit() {
      try {
        if (!this.form.name?.trim()) {
          throw '请输入申请人姓名';
        }

        if (!isMobile(this.form.mobile)) {
          throw '请输入正常的申请人手机号';
        }

        if (!this.form.smsCode?.trim()) {
          throw '请输入验证码';
        }

        if (this.form.smsCode.length < 4) {
          throw '请输入正确的验证码';
        }

        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        let data = await loanIncomingPartsApplyFirstPost({
          mobile: this.form.mobile,
          name: this.form.name,
          brokerId: this.brokerId || 0,
          // 1.0.4屏蔽
          /* latitude: this.form.latitude,
          longitude: this.form.longitude, */
          productNo: this.productNo,
          smsCode: this.form.smsCode,
        });
        if (!data) data = {};

        Toast.clear();

        if (data?.url) {
          // 跳转结果页
          // 1.0.4废弃
          /* window.history.go(-(window.history.length - 1));
          setTimeout(() => {
            window.location.replace(data.url);
          }, 0); */
          window.location.href = data.url;

          //
        } else {
          if (data.annualIncome) data.annualIncome /= 10000;
          if (data.applyAmount) data.applyAmount /= 10000;
          sessionStorage.enterDataForm = JSON.stringify({
            ...this.form,
            ...data,
            productNo: this.productNo,
            brokerId: this.brokerId || 0,
            channelCode: this.channelCode,
            officialAccountKey: this.officialAccountKey,
            invitationCode: this.invitationCode,
            name: this.form.name,
            mobile: this.form.mobile,
            orderNo: data.orderNo,
          });
          this.$router.push('/second');

          //
        }

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 发送验证码
    async sendCodeAction() {
      try {
        if (this.status) return;
        if (!isMobile(this.form.mobile)) {
          throw '请输入正常的申请人手机号';
        }
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        await loanIncomingPartsSms({
          mobile: this.form.mobile,
        });

        Toast.clear();

        this.starTime();
      } catch (err) {
        Toast(err);
      }
    },
    starTime() {
      if (this.status) return;
      this.status = true;
      this.loading();
      this.timer = setInterval(() => {
        if (this.num === 0) {
          this.timer && this.clearTimer();
          this.reset();
        } else {
          this.loading();
        }
      }, 1000);
    },
    reset() {
      this.num = 60;
      this.status = false;
      this.texts = '发送验证码';
    },
    loading() {
      this.num -= 1;
      this.texts = this.num + 's后重获';
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },

    //
  },
};
</script>
<style lang="scss">
.send_code {
  margin-left: 15px;
  font-size: 13px;
  color: #fff;
  > div {
    padding: 5px 10px;
    border-radius: 5px;
  }
  .bg_afafaf {
    background: #afafaf;
  }
}
</style>
