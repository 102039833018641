import * as http from '@/utils/http';

// 新 检测区域是否开通
export const loanIncomingPartsOrderNoCheckAreaPost = async (orderNo, data) => {
  const res = await http.post(`loan/incoming/parts/${orderNo}/channel/check/area`, data);
  return res.data;
};

// 检测区域是否开通
export const loanIncomingPartsProductNoCheckAreaPost = async (productNo, data) => {
  const res = await http.post(`/loan/incoming/parts/${productNo}/check/area`, data);
  return res.data;
};

// 行驶证识别
export const loanIncomingPartsDrivingLicensePost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/driving/license`, data);
  return res.data;
};

// 身份证单面上传
export const loanIncomingPartsIdCardOneSidePost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/id-card/one-side`, data);
  return res.data;
};

// 融资进件第三步
export const loanIncomingPartsApplyThirdPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/apply/third`, data);
  return res.data;
};

// 融资申请第二步
export const loanIncomingPartsApplySecondPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/apply/second`, data);
  return res.data;
};

// 融资申请第一步
export const loanIncomingPartsApplyFirstPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/apply/first`, data);
  return res.data;
};

// 根据手机号码发送验证码
export const loanIncomingPartsSms = async (data) => {
  const res = await http.post(`/loan/incoming/parts/sms`, data);
  return res.data;
};

// 文件上传
export const loanIncomingPartsUploadPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/upload`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  });
  return res.data;
};

// 期数-枚举
export const loanIncomingPartsApplyTerm = async () => {
  const res = await http.get(`/loan/incoming/parts/apply/term`);
  if (!res || !res.data) return [];
  return res.data;
};

// 车辆类别-枚举
export const loanIncomingPartsVehicleCategory = async () => {
  const res = await http.get(`/loan/incoming/parts/vehicle/category`);
  if (!res || !res.data) return [];
  return res.data;
};

// 民族-枚举
export const loanIncomingPartsNation = async () => {
  const res = await http.get(`/loan/incoming/parts/nation`);
  if (!res || !res.data) return [];
  return res.data;
};

// 邮政区域获取-只到省市
export const baseAreaProvincialPrefecture = async () => {
  const res = await http.get(`/base/area/provincial/prefecture`);
  if (!res || !res.data) return [];
  return res.data;
};

// 融资申请进件
export const loanIncomingPartsApplyPost = async (data) => {
  const res = await http.post(`/loan/incoming/parts/apply`, data);
  return res.data;
};
