var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.productNo)?_c('div',{staticClass:"min_h_100_vh dis_flex flex_dir_col",style:("background: url('https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/IzGtDsDiUzVatCyqaJyE.png') no-repeat center/cover")},[_c('div',{staticClass:"dis_flex ju_con_cen font_32 col_fff text_sha_0_2_4_CBE7FE m_t_32"},[_vm._v(" 钱拿走 车照开 ")]),_c('div',{staticClass:"dis_flex ju_con_cen font_14 col_fff m_t_24"},[_vm._v(" 快速审批/多城市覆盖/专业评估/在线签约 ")]),_vm._m(0),_c('div',{staticClass:"bg_col_fff bor_rad_8 p_16 m_0_16 m_t_16"},[_vm._m(1),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_0 m_t_16"},[_c('div',{staticClass:"font_14"},[_vm._v("申请人姓名")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"w_0 flex_1 font_14 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"10"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})]),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_0"},[_c('div',{staticClass:"font_14"},[_vm._v("申请人手机号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mobile),expression:"form.mobile"}],staticClass:"w_0 flex_1 font_14 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"11"},domProps:{"value":(_vm.form.mobile)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "mobile", $event.target.value)},function (e) {
            var v = e.currentTarget.value;
            v = (v || '').replace(/[^0-9]/g, '');
            _vm.form = Object.assign({}, _vm.form, {mobile: v});
          }]}})]),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_0"},[_c('div',{staticClass:"font_14"},[_vm._v("验证码")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.smsCode),expression:"form.smsCode"}],staticClass:"w_0 flex_1 font_14 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"4"},domProps:{"value":(_vm.form.smsCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "smsCode", $event.target.value)},function (e) {
            var v = e.currentTarget.value;
            v = (v || '').replace(/[^0-9]/g, '');
            _vm.form = Object.assign({}, _vm.form, {smsCode: v});
          }]}}),_c('div',{staticClass:"font_14 send_code",on:{"click":_vm.sendCodeAction}},[(+_vm.num === 60)?_c('div',{staticClass:"bg_col_2396F5"},[_vm._v(_vm._s(_vm.texts))]):_c('div',{staticClass:"bg_afafaf"},[_vm._v(_vm._s(_vm.texts))])])]),_c('div',{staticClass:"h_1 bg_col_F5F7F9"}),_c('div',{staticClass:"\n        dis_flex\n        ju_con_cen\n        font_16\n        l_h_100_per\n        col_fff\n        bg_col_2396F5\n        active_opa_75_per\n        bor_rad_24\n        p_16_0\n        m_0_16 m_t_24\n      ",on:{"click":function($event){return _vm.submit()}}},[_vm._v(" 立即申请 ")])]),_vm._m(2),_c('div',{staticClass:"dis_flex ju_con_cen m_0_16 m_t_24"},_vm._l(([
        {
          label: '在线审批',
          iconUrl:
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/hgwmUBsftnmTbZtumZCS.png',
        },
        {
          label: '在线签约',
          iconUrl:
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/rBDBJTAmCrxHrSFsGqEm.png',
        },
        {
          label: '抵押登记',
          iconUrl:
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/XapfMwmBXvuDXrRmTWbm.png',
        },
        {
          label: '钱款到账',
          iconUrl:
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/ldNFTINZIoeBKIcWvhwE.png',
        } ]),function(item,index){return _c('div',{key:index,staticClass:"dis_flex ali_it_cen"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(index > 0),expression:"index > 0"}],staticClass:"h_12 dis_flex m_0_8",attrs:{"src":"https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/OgzoOZGuGcpSsgoEFxsz.png"}}),_c('div',{staticClass:"flex_1 dis_flex flex_dir_col ali_it_cen"},[_c('div',{staticClass:"\n            w_56\n            h_56\n            dis_flex\n            ju_con_cen\n            ali_it_cen\n            bg_col_fff\n            bor_rad_50_per\n          "},[_c('img',{staticClass:"w_32 h_32 dis_flex",attrs:{"src":item.iconUrl}})]),_c('div',{staticClass:"font_14 col_fff white_space_nowrap m_t_8"},[_vm._v(" "+_vm._s(item.label)+" ")])])])}),0),_c('div',{staticClass:"h_56"})]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dis_flex ju_con_cen"},[_c('img',{staticClass:"w_160 h_160 dis_flex",attrs:{"src":"https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/riCWSfZSIjosNfhhmjPI.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dis_flex ju_con_cen ali_it_cen"},[_c('img',{staticClass:"h_16 dis_flex",attrs:{"src":"https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/PrKkkLMnXlNZyNQXDSaY.png"}}),_c('div',{staticClass:"font_18 font_bold col_2396F5 white_space_nowrap m_0_16"},[_vm._v(" 申请信息 ")]),_c('img',{staticClass:"h_16 dis_flex tras_rot_180_deg",attrs:{"src":"https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220708/PrKkkLMnXlNZyNQXDSaY.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dis_flex ju_con_cen"},[_c('div',{staticClass:"\n        font_18\n        l_h_100_per\n        col_fff\n        bg_col_lin_90_51a4fd_389df9\n        bor_rad_24\n        p_8_16\n        m_t_32\n      "},[_vm._v(" 简单4步 轻松到账 ")])])}]

export { render, staticRenderFns }