<template>
  <div
    v-if="form.productNo"
    class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9"
  >
    <!-- 步骤 -->
    <EnterDataStep :step="2" />

    <!-- 块 -->
    <div class="pos_rel bg_col_fff bor_rad_8 over_hid p_16 m_0_16 m_t_neg_48">
      <!-- 标题 -->
      <div class="dis_flex ali_it_cen">
        <div class="flex_1 font_18 font_bold">上传行驶证</div>
        <div class="font_12 col_666">请上传清晰照片，四角齐全</div>
      </div>

      <!-- 上传行驶证 -->
      <div class="dis_flex m_t_16">
        <div class="flex_1">
          <UploadFile
            class="h_24_vw"
            :src="
              form.driveLicenseFrontUrl ||
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/sCdKbyCyNiIHLJTkJINE.png'
            "
            :isOnlyImg="true"
            :isReturnFile="true"
            @handleUpload="(file) => drivingLicenseOCR(file)"
          />

          <div class="dis_flex ju_con_cen font_14 col_666 m_t_8">
            点击上传正本面
          </div>

          <!--  -->
        </div>

        <div class="flex_1 m_l_16">
          <UploadFile
            class="h_24_vw"
            :src="
              form.driveLicenseBackUrl ||
              'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/XSeQKWfgpooIZDLgTejm.png'
            "
            :isOnlyImg="true"
            @handleUpload="(v) => (form = { ...form, driveLicenseBackUrl: v })"
          />

          <div class="dis_flex ju_con_cen font_14 col_666 m_t_8">
            点击上传副本面
          </div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 块 -->
    <div class="bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16">
      <!-- 标题 -->
      <div class="font_18 font_bold p_16">车辆信息</div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">车牌号</div>

        <input
          class="w_0 flex_1 font_16 text_ali_r bor_none m_l_16"
          v-model="form.vehiclePlateNo"
          type="text"
          placeholder="请输入"
          maxlength="20"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = v.toUpperCase();
              form = { ...form, vehiclePlateNo: v };
            }
          "
          @blur="getCityInfo()"
        />
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">车架号</div>

        <input
          class="w_0 flex_1 font_16 text_ali_r bor_none m_l_16"
          v-model="form.vehicleVin"
          type="text"
          placeholder="请输入"
          maxlength="17"
          @input="
            (e) => {
              let v = e.currentTarget.value;
              v = v.toUpperCase();
              form = { ...form, vehicleVin: v };
            }
          "
        />

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">车辆全称</div>

        <input
          class="w_0 flex_1 font_16 text_ali_r bor_none m_l_16"
          v-model="form.vehicleFullName"
          type="text"
          placeholder="请输入"
          maxlength="20"
        />
      </div>

      <!-- 灰线 -->
      <!-- <div class="h_1 bg_col_eee"></div> -->

      <!-- 1.0.0.1废弃 -->
      <!-- 项 -->
      <!-- <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
      <div class="font_16">车辆类别</div>

      <div
        class="flex_1 font_16 text_ali_r m_l_16"
        @click="form = { ...form, carTypeDialogShow: true }"
      >
        {{
          (
            vehicleCategoryList.find(
              (it) => it.value == form.vehicleCategory
            ) || {}
          ).label || '请选择'
        }}
      </div>

      <PickEnum
        :show.sync="form.carTypeDialogShow"
        :columns="vehicleCategoryList"
        @handleConfirm="(v) => (form = { ...form, vehicleCategory: v.value })"
      />
    </div> -->

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">上牌地</div>

        <div class="flex_1 font_16 text_ali_r m_l_16">
          {{ form.cityName || '-' }}
        </div>

        <!-- 2.3.4废弃 -->
        <!-- <div
          class="flex_1 font_16 text_ali_r m_l_16"
          @click="form = { ...form, installPlatePlaceDialogShow: true }"
        >
          {{
            form.installPlatePlaceSelected
              ? `${form.installPlatePlaceSelected[0].name} - ${form.installPlatePlaceSelected[1].name}`
              : '请选择'
          }}
        </div> -->

        <!-- 上牌地 弹窗 -->
        <!-- 2.3.4废弃 -->
        <!-- <van-popup v-model="form.installPlatePlaceDialogShow" position="bottom">
          <van-cascader
            :field-names="{
              text: 'name',
              value: 'code',
              children: 'items',
            }"
            :options="areaProvincialList"
            @finish="
              (v) => {
                form = {
                  ...form,
                  installPlatePlaceSelected: v.selectedOptions,
                  cityCode: v.selectedOptions[1].code,
                  installPlatePlaceDialogShow: false,
                };
              }
            "
            @close="form = { ...form, installPlatePlaceDialogShow: false }"
          /> -->

        <!--  -->
        <!-- </van-popup> -->

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">上牌时间</div>

        <div
          class="flex_1 font_16 text_ali_r m_l_16"
          @click="form = { ...form, installplateTimeDialogShow: true }"
        >
          {{ form.vehicleCardTimeStr || '请选择' }}
        </div>

        <!-- 车辆选择 弹窗 -->
        <van-popup v-model="form.installplateTimeDialogShow" position="bottom">
          <van-datetime-picker
            type="date"
            title="选择年月日"
            @confirm="
              (v) =>
                (form = {
                  ...form,
                  vehicleCardTimeStr: moment(v).format('YYYY-MM-DD'),
                  vehicleCardTime: moment(v).format('YYYY-MM-DD HH:mm:ss'),
                  installplateTimeDialogShow: false,
                })
            "
            @cancel="form = { ...form, installplateTimeDialogShow: false }"
          />

          <!--  -->
        </van-popup>

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">车辆价值</div>

        <div class="flex_1 dis_flex ali_it_cen m_l_16">
          <input
            class="w_0 flex_1 font_16 text_ali_r bor_none"
            v-model="form.carPrice"
            type="text"
            placeholder="请输入"
            maxlength="7"
            @input="
              (e) => {
                let v = e.currentTarget.value;
                v = (v || '').replace(/[^0-9]/g, '');
                form = { ...form, carPrice: v };
              }
            "
          />

          <div class="font_16 m_l_8">元</div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!-- 灰线 -->
      <div class="h_1 bg_col_eee"></div>

      <!-- 项 -->
      <div class="dis_flex ali_it_cen bg_col_fff p_16_24">
        <div class="font_16">行驶里程</div>

        <div class="flex_1 dis_flex ali_it_cen m_l_16">
          <input
            class="w_0 flex_1 font_16 text_ali_r bor_none"
            v-model="form.workDistance"
            type="text"
            placeholder="请输入"
            maxlength="7"
            @input="
              (e) => {
                let v = e.currentTarget.value;
                v = (v || '').replace(/[^0-9]/g, '');
                form = { ...form, workDistance: v };
              }
            "
          />

          <div class="font_16 m_l_8">公里</div>

          <!--  -->
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 留白 -->
    <div class="h_96"></div>

    <!-- 提交 -->
    <div class="pos_fixed l_0 b_0 z_1 w_100_per bg_col_F5F7F9">
      <div class="dis_flex p_16_24">
        <van-button class="flex_1" type="info" @click="submit()"
          >提交</van-button
        >
      </div>
    </div>

    <!-- 倒计时弹窗 -->
    <van-popup
      class="bg_col_tra bor_rad_8"
      v-model="countdownDialogShow"
      :close-on-click-overlay="false"
    >
      <div class="w_72_vw dis_flex flex_dir_col ali_it_cen p_56_16">
        <div class="pos_rel">
          <!-- 背景 环 -->
          <img
            class="w_120 h_120 dis_flex ani_rot_360"
            src="https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220706/mrUMrRcbqCFYCmHsbjoQ.png"
          />

          <!-- 秒 -->
          <div
            class="
              pos_abs
              l_0
              t_0
              w_100_per
              h_100_per
              dis_flex
              ju_con_cen
              ali_it_cen
            "
          >
            <div class="font_32 font_bold">{{ countdownNumber }}</div>
          </div>

          <!--  -->
        </div>
        <div class="font_16 m_t_24">正等待返回结果...</div>
        <div class="font_12 col_F5A227 m_t_16">结果返回前，请不要重复操作</div>

        <!--  -->
      </div>

      <!--  -->
    </van-popup>

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
const moment = window.moment;
import { isVehicleNumber } from '@/utils/tools';

import {
  loanIncomingPartsOrderNoCheckAreaPost,
  loanIncomingPartsDrivingLicensePost,
  loanIncomingPartsVehicleCategory,
  baseAreaProvincialPrefecture,
  loanIncomingPartsApplyThirdPost,
} from '@/api/index';

export default {
  components: {
    UploadFile: () => import('@/components/UploadFile'),
    EnterDataStep: () => import('@/components/EnterDataStep'),
  },
  data() {
    return {
      form: {},

      areaProvincialList: [], // 省市
      vehicleCategoryList: [], // 车辆类别

      /**
       * 倒计时 弹窗
       */
      countdownDialogShow: false,
      countdownNumber: 0,

      //
    };
  },
  computed: {},
  mounted() {
    if (!sessionStorage.enterDataForm) {
      alert('没有检测到携带信息，请离开');
      return;
    }
    
    let form = JSON.parse(sessionStorage.enterDataForm);
    if (form.vehicleCardTime) {
      form.vehicleCardTimeStr = moment(form.vehicleCardTime).format('YYYY-MM-DD');
    }
    this.form = form;

    this.getAreaProvincialList();
    this.getVehicleCategoryList();

    this.getCityInfo();

    //
  },
  destroyed() {
    clearTimeout(window.countdownTimeout);
  },
  methods: {
    moment,

    // 提交
    async submit() {
      try {
        if (!this.form.driveLicenseFrontUrl) {
          throw '请上传行驶证正本面';
        }

        if (!this.form.driveLicenseBackUrl) {
          throw '请上传行驶证副本面';
        }

        if (!isVehicleNumber(this.form.vehiclePlateNo)) {
          throw '请输入正确的车牌号';
        }

        if (!this.form.vehicleVin?.trim()) {
          throw '请输入车架号';
        }

        if (!this.form.vehicleFullName?.trim()) {
          throw '请输入车辆全称';
        }

        // 1.0.0.1废弃
        /* if (!this.form.vehicleCategory) {
          throw '请选择车辆类别';
        } */

        if (!this.form.cityCode) {
          throw '请确保行驶证识别上牌地';
        }

        if (!this.form.vehicleCardTime) {
          throw '请选择上牌时间';
        }

        if (!this.form.carPrice) {
          throw '请输入车辆价值';
        }

        if (!this.form.workDistance) {
          throw '请输入行驶里程';
        }

        sessionStorage.enterDataForm = JSON.stringify({
          ...this.form,
          vehiclePlateNo: this.form.vehiclePlateNo,
          vehicleVin: this.form.vehicleVin,
          vehicleFullName: this.form.vehicleFullName,
          cityCode: this.form.cityCode,
          vehicleCardTime: this.form.vehicleCardTime,
          carPrice: this.form.carPrice,
        });

        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await loanIncomingPartsApplyThirdPost({
          carPrice: this.form.carPrice,
          cityCode: this.form.cityCode,
          driveLicenseBackUrl: this.form.driveLicenseBackUrl,
          driveLicenseFrontUrl: this.form.driveLicenseFrontUrl,
          orderNo: this.form.orderNo,
          vehiclePlateNo: this.form.vehiclePlateNo,
          vehicleVin: this.form.vehicleVin,
          workDistance: this.form.workDistance,
          vehicleCardTime: this.form.vehicleCardTime,
          vehicleFullName: this.form.vehicleFullName,
        });

        Toast.clear();

        if (data) {
          // 5秒倒计时
          this.countdownDialogShow = true;
          this.countdown(5).then(() => {
            // 跳转结果页
            // 1.0.4废弃
            /* window.history.go(-(window.history.length - 1));
            setTimeout(() => {
              window.location.replace(data);
            }, 0); */
            window.location.href = data;

            //
          });

          //
        } else {
          Toast('准入不通过');

          setTimeout(() => {
            this.$router.go(-2);
          }, 1000);

          //
        }

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 倒计时
    async countdown(second) {
      this.countdownNumber = second;
      if (second > 0) {
        await new Promise((resolve) => {
          window.countdownTimeout = setTimeout(() => {
            resolve();
          }, 1000);
        });
        await this.countdown(--second);
      }
    },

    // 获取 上牌地
    async getCityInfo() {
      if (!this.form.vehiclePlateNo) return;

      try {
        const data = await loanIncomingPartsOrderNoCheckAreaPost(
          this.form.orderNo,
          {
            licensePlate: this.form.vehiclePlateNo,
          }
        );

        this.form = {
          ...this.form,
          cityName: `${data.provincial}-${data.prefecture}`,
          cityCode: data.prefectureCode,
        };

        //
      } catch (err) {
        Toast(err);
      }

      //
    },

    // 行驶证 OCR
    async drivingLicenseOCR(file) {
      let params = new FormData();
      params.append('file', file);

      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await loanIncomingPartsDrivingLicensePost(params);

        Toast.clear();

        let form = {
          ...this.form,
        };
        if (data.licensePlate) form.vehiclePlateNo = data.licensePlate;
        if (data.drivingLicense)
          form.driveLicenseFrontUrl = data.drivingLicense;
        if (data.vin) form.vehicleVin = data.vin;
        if (data.vehicleModel) form.vehicleFullName = data.vehicleModel;

        this.form = form;

        this.getCityInfo();

        //
      } catch (err) {
        Toast.clear();
        Toast(err);
      }

      //
    },

    // 获取 车辆类别
    async getVehicleCategoryList() {
      let list = await loanIncomingPartsVehicleCategory();
      this.vehicleCategoryList = list.map((item) => {
        return {
          ...item,
          label: item.value,
          value: item.key,
        };
      });
    },

    // 获取 省市
    async getAreaProvincialList() {
      let list = await baseAreaProvincialPrefecture();
      let provinceList = list.filter((it) => it.higherLevel == '0');
      let cityList = list.filter((it) => it.higherLevel != '0');

      provinceList.forEach((item) => {
        item.items = [];
        cityList.forEach((item1) => {
          if (item1.higherLevel == item.code) {
            item.items.push(item1);
          }
        });
      });

      this.areaProvincialList = provinceList;
    },

    //
  },
};
</script>
