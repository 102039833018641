var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form.productNo)?_c('div',{staticClass:"min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9"},[_c('EnterDataStep',{attrs:{"step":2}}),_c('div',{staticClass:"pos_rel bg_col_fff bor_rad_8 over_hid p_16 m_0_16 m_t_neg_48"},[_vm._m(0),_c('div',{staticClass:"dis_flex m_t_16"},[_c('div',{staticClass:"flex_1"},[_c('UploadFile',{staticClass:"h_24_vw",attrs:{"src":_vm.form.driveLicenseFrontUrl ||
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/sCdKbyCyNiIHLJTkJINE.png',"isOnlyImg":true,"isReturnFile":true},on:{"handleUpload":function (file) { return _vm.drivingLicenseOCR(file); }}}),_c('div',{staticClass:"dis_flex ju_con_cen font_14 col_666 m_t_8"},[_vm._v(" 点击上传正本面 ")])],1),_c('div',{staticClass:"flex_1 m_l_16"},[_c('UploadFile',{staticClass:"h_24_vw",attrs:{"src":_vm.form.driveLicenseBackUrl ||
            'https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/XSeQKWfgpooIZDLgTejm.png',"isOnlyImg":true},on:{"handleUpload":function (v) { return (_vm.form = Object.assign({}, _vm.form, {driveLicenseBackUrl: v})); }}}),_c('div',{staticClass:"dis_flex ju_con_cen font_14 col_666 m_t_8"},[_vm._v(" 点击上传副本面 ")])],1)])]),_c('div',{staticClass:"bg_col_fff bor_rad_8 over_hid m_0_16 m_t_16"},[_c('div',{staticClass:"font_18 font_bold p_16"},[_vm._v("车辆信息")]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("车牌号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehiclePlateNo),expression:"form.vehiclePlateNo"}],staticClass:"w_0 flex_1 font_16 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"20"},domProps:{"value":(_vm.form.vehiclePlateNo)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "vehiclePlateNo", $event.target.value)},function (e) {
            var v = e.currentTarget.value;
            v = v.toUpperCase();
            _vm.form = Object.assign({}, _vm.form, {vehiclePlateNo: v});
          }],"blur":function($event){return _vm.getCityInfo()}}})]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("车架号")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehicleVin),expression:"form.vehicleVin"}],staticClass:"w_0 flex_1 font_16 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"17"},domProps:{"value":(_vm.form.vehicleVin)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "vehicleVin", $event.target.value)},function (e) {
            var v = e.currentTarget.value;
            v = v.toUpperCase();
            _vm.form = Object.assign({}, _vm.form, {vehicleVin: v});
          }]}})]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("车辆全称")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehicleFullName),expression:"form.vehicleFullName"}],staticClass:"w_0 flex_1 font_16 text_ali_r bor_none m_l_16",attrs:{"type":"text","placeholder":"请输入","maxlength":"20"},domProps:{"value":(_vm.form.vehicleFullName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "vehicleFullName", $event.target.value)}}})]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("上牌地")]),_c('div',{staticClass:"flex_1 font_16 text_ali_r m_l_16"},[_vm._v(" "+_vm._s(_vm.form.cityName || '-')+" ")])]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("上牌时间")]),_c('div',{staticClass:"flex_1 font_16 text_ali_r m_l_16",on:{"click":function($event){_vm.form = Object.assign({}, _vm.form, {installplateTimeDialogShow: true})}}},[_vm._v(" "+_vm._s(_vm.form.vehicleCardTimeStr || '请选择')+" ")]),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.form.installplateTimeDialogShow),callback:function ($$v) {_vm.$set(_vm.form, "installplateTimeDialogShow", $$v)},expression:"form.installplateTimeDialogShow"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日"},on:{"confirm":function (v) { return (_vm.form = Object.assign({}, _vm.form,
                {vehicleCardTimeStr: _vm.moment(v).format('YYYY-MM-DD'),
                vehicleCardTime: _vm.moment(v).format('YYYY-MM-DD HH:mm:ss'),
                installplateTimeDialogShow: false})); },"cancel":function($event){_vm.form = Object.assign({}, _vm.form, {installplateTimeDialogShow: false})}}})],1)],1),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("车辆价值")]),_c('div',{staticClass:"flex_1 dis_flex ali_it_cen m_l_16"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.carPrice),expression:"form.carPrice"}],staticClass:"w_0 flex_1 font_16 text_ali_r bor_none",attrs:{"type":"text","placeholder":"请输入","maxlength":"7"},domProps:{"value":(_vm.form.carPrice)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "carPrice", $event.target.value)},function (e) {
              var v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              _vm.form = Object.assign({}, _vm.form, {carPrice: v});
            }]}}),_c('div',{staticClass:"font_16 m_l_8"},[_vm._v("元")])])]),_c('div',{staticClass:"h_1 bg_col_eee"}),_c('div',{staticClass:"dis_flex ali_it_cen bg_col_fff p_16_24"},[_c('div',{staticClass:"font_16"},[_vm._v("行驶里程")]),_c('div',{staticClass:"flex_1 dis_flex ali_it_cen m_l_16"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.workDistance),expression:"form.workDistance"}],staticClass:"w_0 flex_1 font_16 text_ali_r bor_none",attrs:{"type":"text","placeholder":"请输入","maxlength":"7"},domProps:{"value":(_vm.form.workDistance)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "workDistance", $event.target.value)},function (e) {
              var v = e.currentTarget.value;
              v = (v || '').replace(/[^0-9]/g, '');
              _vm.form = Object.assign({}, _vm.form, {workDistance: v});
            }]}}),_c('div',{staticClass:"font_16 m_l_8"},[_vm._v("公里")])])])]),_c('div',{staticClass:"h_96"}),_c('div',{staticClass:"pos_fixed l_0 b_0 z_1 w_100_per bg_col_F5F7F9"},[_c('div',{staticClass:"dis_flex p_16_24"},[_c('van-button',{staticClass:"flex_1",attrs:{"type":"info"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("提交")])],1)]),_c('van-popup',{staticClass:"bg_col_tra bor_rad_8",attrs:{"close-on-click-overlay":false},model:{value:(_vm.countdownDialogShow),callback:function ($$v) {_vm.countdownDialogShow=$$v},expression:"countdownDialogShow"}},[_c('div',{staticClass:"w_72_vw dis_flex flex_dir_col ali_it_cen p_56_16"},[_c('div',{staticClass:"pos_rel"},[_c('img',{staticClass:"w_120 h_120 dis_flex ani_rot_360",attrs:{"src":"https://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220706/mrUMrRcbqCFYCmHsbjoQ.png"}}),_c('div',{staticClass:"\n            pos_abs\n            l_0\n            t_0\n            w_100_per\n            h_100_per\n            dis_flex\n            ju_con_cen\n            ali_it_cen\n          "},[_c('div',{staticClass:"font_32 font_bold"},[_vm._v(_vm._s(_vm.countdownNumber))])])]),_c('div',{staticClass:"font_16 m_t_24"},[_vm._v("正等待返回结果...")]),_c('div',{staticClass:"font_12 col_F5A227 m_t_16"},[_vm._v("结果返回前，请不要重复操作")])])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dis_flex ali_it_cen"},[_c('div',{staticClass:"flex_1 font_18 font_bold"},[_vm._v("上传行驶证")]),_c('div',{staticClass:"font_12 col_666"},[_vm._v("请上传清晰照片，四角齐全")])])}]

export { render, staticRenderFns }